<template>
  <div class="task-item" @click="click" :class="{disabled}">
  <div @click="emit('changeStatus', task, index, category)">
        <input  :class="status === 'in-review' ? 'checked' : 'unchecked'" ref="checkbox" :disabled="status === 'completed'"  type="checkbox" :checked="status==='completed' || status==='in-review'">
        <!-- <input type="checkbox" :class="'unchecked'" ref="checkbox" :disabled="false"> -->
        </div>
    <p @click="taskClicked(number)" :class="getText(status)">{{name}}</p>
    <div @click="taskClicked(number)" style="display: flex; align-items: center; column-gap: 15px; margin-left: 15px;">
        <v-tooltip v-if="task.tooltip">
        <img :src="info" style="margin-left: 5px;" alt="info" />
        <template #popper>
          <span class="tooltip-text">{{task.tooltip}}</span>
        </template>
      </v-tooltip>
    <!-- <a :href="task.link"> -->
      <img v-if="task.link" @click="openExternal(task)" :src="status === 'completed' || status === 'in-review' ? externalLinkDisabled : externalLink" alt="external link icon" />
      <!-- </a> -->
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, onMounted } from 'vue'
import statuses from '@/data/statuses'
import externalLink from '@/assets/icons/external-link.svg'
import externalLinkDisabled from '@/assets/icons/external-link-disabled.png'
import { useRouter } from 'vue-router'
import info from '@/assets/icons/info.svg'

const router = useRouter()
const checkbox = ref(null)
console.log('status:', props.status)
const getText = (status) => {
  if (status === 'completed' || status === 'in-review') return 'finished-task'
  else return 'unfinished-task'
}
const openExternal = (task) => {
  window.open(task.link, '_blank')
}
const taskClicked = (number) => {
  // router.push(`/task/${number}`)
}
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  task: {
    required: true
  },
  category: {
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  status: {
    type: String,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  // number: {
  //   type: String,
  //   required: true
  // }
})

const emit = defineEmits(['click', 'changeStatus'])

const statusIcon = computed(() => {
  const status = statuses.find(status => status.value === props.status)
  return props.disabled ? status.iconDisabled ?? status.icon : status.icon
})

const click = () => {
  if (!props.disabled) {
    emit('click')
  }
}
</script>

<style scoped>
.task-item {
  font-family: 'Helvetica Neue LT Std';
font-style: normal;
font-weight: 700;
font-size: 14px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-bottom: 1px solid #f4f6f5;
  cursor: pointer;
}

/*.task-item-name {*/
/*  !* Check if this work in fillWidth (not adaptive) *!*/
/*  width: 300px;*/
/*  white-space: nowrap;*/
/*  overflow: hidden;*/
/*  text-overflow: ellipsis;*/
/*}*/

.task-item:last-child {
  border-bottom: none;
}
input[type="checkbox"] {
margin-right: 13px;
width: 21px;
height: 21px;
/* HD Orange */
color: #F96302;
border: 1px solid #C1C1C1;
border-radius: 3px;
}
input[type="checkbox"]:checked {
accent-color: #F96302;
pointer-events: none;

}
.checked{
}
.disabled {
  color: #c9ccce;
}
.tooltip-text {
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
}
.finished-task{
font-family: 'Helvetica Neue LT Std';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 120%;
display: flex;
align-items: center;
text-decoration-line: line-through;

/* Strikeout */

color: #908F8F;
}
.unfinished-task{
font-family: 'Helvetica Neue LT Std';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 120%;
display: flex;
align-items: center;
}
</style>
