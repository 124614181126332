<template>
  <section>
    <div class="divider" />
    <Help
      :tasks="allTasks"
      v-if="showModal"
      @showModal="(i) => (showModal = i)"
      @changeStatus="(selected, explanation) => handleAssistance(selected, explanation)"
      class="assistance-modal"
    />
    <div v-if="uncheckModal" class="uncheck-modal">
      <div>
        <div class="bold">Are you sure?</div>
        <div class="exlpanation">
          Unchecking a task affects your progress and notifies your trainer.
        </div>
      </div>
      <div class="button-area">
        <div class="back" @click="uncheckModal = false">Cancel</div>
        <div class="submit" @click="handleSubmit()">Submit</div>
      </div>
    </div>
    <div v-if="showCompleteModal" class="uncheck-modal">
      <div>
        <div class="bold">Nice!</div>
        <div class="exlpanation">
          You’re one step closer to completing your onboarding material and
          finishing your house! Keep an eye on those notifications for updates
          from your leader.
        </div>
      </div>
      <div class="button-area">
        <div class="submit" @click="showCompleteModal = false">Continue</div>
      </div>
    </div>
    <div v-if="uncheckModal" class="uncheck-modal">
      <div>
        <div class="bold">Are you sure?</div>
        <div class="exlpanation">
          Unchecking a task affects your progress and notifies your trainer.
        </div>
      </div>
      <div class="button-area">
        <div class="back" @click="uncheckModal = false">Cancel</div>
        <div class="submit" @click="handleSubmit()">Submit</div>
      </div>
    </div>
    <div class="months-blocks">
      <div class="month-block workday">
        <div class="month-number">
          <span class="month-number-text">Workday</span>
          <div class="completed">
            {{ workdayCompleted }}/{{ workday.length }} tasks complete
          </div>
        </div>
        <CircularProgress
          class="circle"
          :value="Math.floor((workdayCompleted / workday.length) * 100)"
        />
      </div>
      <div class="month-block badging">
        <div class="month-number">
          <span class="month-number-text">Badging</span>
          <div class="completed">
            {{ badgingCompleted }}/{{ badging.length }} tasks complete
          </div>
        </div>
        <CircularProgress
          class="circle"
          :value="Math.floor((badgingCompleted / badging.length) * 100)"
        />
      </div>
    </div>
    <div class="months-blocks">
      <div class="task-block">
        <div class="title">Workday</div>
        <!-- <TaskStatusedItem
              v-for="task in courses[0].tasks"
              :key="task.name"
              :name="task.title"
              :status="task.status"
              :disabled="Math.random() > 0.5"
              @click="taskClicked(task)"
            /> -->
        <!-- <div class="task" v-for="task in courses[0].tasks" :key="task.number"> -->
        <HRItem
          v-for="(task, index) in workday"
          :key="task.name"
          :name="task.name"
          :status="task.status"
          :index="index"
          :task="task"
          category="workday"
          @changeStatus="(task, i) => updateStatus(task, i, 0)"
        />
      </div>
      <div class="task-block">
        <div class="title">Badging</div>
        <!-- <TaskStatusedItem
              v-for="task in courses[0].tasks"
              :key="task.name"
              :name="task.title"
              :status="task.status"
              :disabled="Math.random() > 0.5"
              @click="taskClicked(task)"
            /> -->
        <HRItem
          v-for="(task, index) in badging"
          :key="task.name"
          :name="task.name"
          :index="index"
          :status="task.status"
          :task="task"
          category="badging"
          @changeStatus="(task, i) => updateStatus(task, i, 1)"
        />
      </div>
    </div>
    <AssistanceButton v-if="showAssistanceButton" @click="showModal = true" />
    <!-- <StatusKeyHelper /> -->
  </section>
</template>

<script setup>
import CircularProgress from "@/components/CircularProgress";
import StatusKeyHelper from "@/components/StatusKeyHelper";
import splitTasksArrayByHalfs from "@/utils/splitTasksArrayByHalfs";
import HRItem from "@/components/HRItem";
import Help from "@/components/Help";
import AssistanceButton from "@/components/AssistanceButton";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import ChecklistAPI from "@/api/ChecklistAPI";
const showCompleteModal = ref(false);
const badging = ref();
const workday = ref();
const allTasks = ref();
const showAssistanceButton = ref(true)
onMounted(async () => {
  await getTasks();
});
const workdayCompleted = ref(0);
const badgingCompleted = ref(0);

const getTasks = async () => {
  const checklist = await ChecklistAPI.getChecklist();
  const workdayList = checklist.find((task) => task.name === "Workday");
  workdayList.tasks.sort(function (a, b) {
    return a.order - b.order;
  });
  const badgingList = checklist.find((task) => task.name === "Badging");
  badgingList.tasks.sort(function (a, b) {
    return a.order - b.order;
  });
  workdayCompleted.value = 0;
  badgingCompleted.value = 0;
  badgingList.tasks.forEach((task) => {
    if (task.progresses) task.status = task.progresses[0]?.status;
    if (task.status === "completed" || task.status === "in-review")
      badgingCompleted.value++;
  });
  workdayList.tasks.forEach((task) => {
    if (task.progresses) task.status = task.progresses[0]?.status;
    if (task.status === "completed" || task.status === "in-review")
      workdayCompleted.value++;
  });
  badging.value = badgingList.tasks;
  console.log(badging.value);
  workday.value = workdayList.tasks;
  allTasks.value = badging.value.concat(workday.value);
  console.log("allTasks:", allTasks);
   if (
      workdayCompleted.value + badgingCompleted.value ===
      allTasks.value.length
    ){
      showAssistanceButton.value = false
    }
  //  workdayPercentDone.value = `${Math.floor(workdayCompleted.value / workday.value.length * 100)}%`
  //  badgingPercentDone.value = `${Math.floor(badgingCompleted.value / badging.value.length * 100)}%`
};

const router = useRouter();

const showModal = ref(false);
const uncheckModal = ref(false);
const currentTask = ref();
const index = ref(null);
const handleAssistance = async (tasks, explanation)=>{
  const ids = []
  tasks.forEach(async task=>{
    ids.push(task.id)
    const update = {
      task_id: task.id,
      status: 'attention-required',
      subtask_status: 'unavailable'

    }
   await ChecklistAPI.updateTask(update)
  })
  
  console.log(ids)
  
   let expl
  if(explanation) expl = explanation
  else expl = 'n/a'
  const payload = {
    problem: expl,
    status: 'active',
    task_ids: ids,
    subtask_ids: [],
  }
  await ChecklistAPI.updateAssistance(payload)
}
const updateStatus = async (task, i, month) => {
  currentTask.value = task;
  if (task.status === "in-review") {
    uncheckModal.value = true;
  } else if (task.status !== "completed") {
    const updatedTask = {
      task_id: task.id,
      status: "in-review",
      subtask_status: 'unavailable'
    };
    await ChecklistAPI.updateTask(updatedTask);
    await getTasks();
    if (
      workdayCompleted.value + badgingCompleted.value ===
      allTasks.value.length
    )
      showCompleteModal.value = true;
  }
  //   if (task.status === 'completed' && month === 0) workdayCompleted.value++
  //   if (task.status === 'completed' && month === 1) badgingCompleted.value++
  // currentMonth.value = month
  // if (task.status === 'available' || task.status === 'attention-required') {
  //   console.log(month)
  //   if (month === 0) {
  //     workdayCompleted.value++
  //     workdayPercentDone.value = `${Math.floor(workdayCompleted.value / workday.value.length * 100)}%`
  //   } else {
  //     badgingCompleted.value++
  //     badgingPercentDone.value = `${Math.floor(badgingCompleted.value / badging.value.length * 100)}%`
  //   }
  //   if (workdayCompleted.value === workday.value.length && badgingCompleted.value / badging.value.length) showCompleteModal.value = true
  // } if (task.status === 'in-review') {
  //   index.value = i
  //   currentMonth.value = month
  //   uncheckModal.value = true
  // } else if (task.status !== 'completed') task.status = 'in-review'
  // courses.value[month].tasks[i] = task
};
const handleSubmit = async () => {
    showAssistanceButton.value = true
  const updatedTask = {
    task_id: currentTask.value.id,
    status: "available",
    subtask_status: 'unavailable'
  };
  await ChecklistAPI.updateTask(updatedTask);

  // await ChecklistAPI.updateTask(currentTask.value)
  await getTasks();
  uncheckModal.value = false;
};
// workday.value.forEach(task => {
//   if (task.status === 'completed' || task.status === 'in-review') workdayCompleted.value++
// })
// badging.value.forEach(task => {
//   if (task.status === 'completed' || task.status === 'in-review') badgingCompleted.value++
// })
// if (workdayCompleted.value === workday.value.length && badgingCompleted.value === badging.value.length) showCompleteModal.value = true
</script>

<style scoped>
CircularProgress {
  margin-bottom: 10px;
}
.divider {
  width: 100%;
  height: 1px;
  margin-bottom: 45px;
  border: 0;
  background: rgba(201, 204, 206, 0.38);
}
.button-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.months-blocks {
  display: flex;
  margin-bottom: 40px;
  column-gap: 20px;
}
.assistance-modal {
  position: fixed;
  left: 43.5%;
  bottom: 13%;
  z-index: 1000;
  /* transform: translate(-50%, -50%); */
}

.month-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 210px;
  padding: 40px;
  border-radius: 20px;
  background: #dbdede;
  width: 458px;
  min-width: 400px;
}
.back {
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #000000;
  margin-right: 10px;
}

.submit {
  background: #f96302;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.uncheck-modal {
  width: 470px;
  height: 350px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  flex-direction: column;
  padding: 20px 60px 20px 60px;
  left: 43.5%;
  bottom: 33%;
  z-index: 1000;
  border: solid 1px black;
  /* transform: translate(-50%, -50%); */
}
.completed {
  margin-top: 20px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 102.1%;
  /* or 16px */

  display: flex;
  align-items: center;

  color: #ffffff;
}
.task-block {
  width: 458px;
  /* height: 291px; */
  background: #ffffff;
  border-radius: 20px;
  padding: 40px;
  min-width: 400px;
}
.bold {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 750;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 20px;
  /* identical to box height */
  color: #000000;
}
.explanation {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 127.5%;
  /* or 20px */

  color: #5f6562;
}
.task {
  width: 313px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* or 53px */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* text-decoration-line: line-through; */

  /* Strikeout */

  color: #908f8f;
}

/* .month-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: 128px;
} */

.month-number-text {
  width: 193px;
  height: 34px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 102.1%;
  /* or 37px */

  display: flex;
  align-items: center;
  letter-spacing: -0.025em;

  color: #ffffff;
}
.circle {
  width: 133px;
  height: 133px;
}
.tasks-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  margin: 20px 0;
  row-gap: 40px;
}

.month-tasks-block {
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;
}

.month-header {
  margin-top: 0;
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
  color: #ff8217;
  text-transform: uppercase;
}

.month-tasks-subblock {
  display: flex;
  justify-content: space-between;
  column-gap: 60px;
}

.tasks-block {
  flex: 1;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
}
.workday {
  width: 458px;
  height: 230px;
  background: linear-gradient(354.24deg, #f96302 -5.58%, #ff9900 121.2%);
  border-radius: 20px;
}
.badging {
  width: 458px;
  height: 230px;
  background: linear-gradient(180deg, #00a1b3 0%, #006873 135.87%);
  border-radius: 20px;
}
.title {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  /* HD Orange */

  color: #f96302;
}
</style>
